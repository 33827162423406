import React, { useContext, useLayoutEffect, useRef } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import TransitionContext from '../context/TransitionContext';

const TransitionComponent = ({ children }) => {
  const location = useLocation();
  const { toggleCompleted } = useContext(TransitionContext);
  const app = useRef();
  const banner = useRef();

  useLayoutEffect(() => {
    gsap.from(banner.current, { yPercent: -300, duration: 2, ease: "slow.inOut", zIndex: 0 })
    let ctx = gsap.context(() => {
    }, app);
    return () => ctx;
  }, []);



  return (
    <>
      <SwitchTransition>
        <Transition
          key={location.pathname}
          timeout={800}
          onEnter={(node) => {
            toggleCompleted(false);
            gsap.set(node, { autoAlpha: 0, scale: 0.8, yPercent: 100 });
            gsap
              .timeline({
                paused: true,
                onComplete: () => toggleCompleted(true),
              })
              .to(node, { autoAlpha: 1, yPercent: 0, duration: 0.5 })
              .to(node, { scale: 1, duration: 0.5, ease: "slow.inOut" })
              .play();
          }}
          onExit={(node) => {

            // gsap.from(banner.current, { yPercent: 200, opacity: 1, duration: 3, zIndex: 10, ease: "slow.inOut" });
            gsap
              .timeline({ paused: true })
              .to(node, { scale: 0.8, duration: 0.5, ease: "slow.inOut" })
              .to(node, { yPercent: -100, autoAlpha: 0, duration: 0.5, ease: "slow.inOut" })
              .play();
          }
          }
        >
          {children}
        </Transition>
      </SwitchTransition>

      {/* <div className="holder sd" ref={app}>
        <div ref={banner} className='banner'>
        </div>
      </div> */}
    </>
  );
};

export default TransitionComponent;
