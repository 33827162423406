import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './Pages/Layout'
import Home from './Pages/Home'
import History from './Pages/History'
import BigBangStarField from 'react-big-bang-star-field'
import TransitionComponent from './Components/Transition';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
import { TransitionProvider } from "./context/TransitionContext";

//Wallet Integration
import { w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'
import Header from './Components/Header'
import Footer from './Components/Footer'
import { useEffect, useState } from 'react'
import Loader from './Components/Loader'
import  { Toaster } from 'react-hot-toast';

const chains = [arbitrum, mainnet, polygon]
const projectId = '3b5b514734de26a64d34b6d0da911c09'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // For demonstration, we'll hide the loader after 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  

  return (
    <>
      <BigBangStarField
        className="bgBody"
        numStars={150}
        maxStarSpeed={5}
        scale={1}
        starColor="252, 255, 255"
      />
    
          <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
      {loading ? <Loader /> :
        <BrowserRouter>
          <WagmiConfig config={wagmiConfig}>
            <Header />
            <TransitionProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <TransitionComponent>
                      <Layout />
                    </TransitionComponent>
                  }
                />
                <Route
                  index
                  element={
                    <TransitionComponent>
                      <Home />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="history"
                  element={
                    <TransitionComponent>
                      <History />
                    </TransitionComponent>
                  }
                />
              </Routes>
            </TransitionProvider>
          </WagmiConfig>
          <Footer />
        </BrowserRouter >
      }
    </>
  )
}

export default App
