import React from 'react'
import { Link } from 'react-router-dom'
import { FaTwitter, FaFacebook,  FaYoutube, FaTelegram,  FaDiscord } from 'react-icons/fa';


const Footer = () => {
    return (
        <>
            <footer className='footer w-full relative border-[#1b2842] border-t-2 mt-16  bottom-0'>
                <div className="container mx-auto px-4 md:px-0">
                    <div className='w-full md:w-11/12 mx-auto py-5'>
                        <div className='flex flex-col md:flex-row  justify-between items-center'>
                            <div className='flex gap-x-3 mb-3 md:mb-0 '>
                                <span className='flex text-slate-400 font-semibold md:text-lg'>&#169; 2023 BY</span><a href="/#" className='uppercase md:text-lg'>has#fair Games</a>
                            </div>
                            <div className="footer_social">
                                <ul className="flex">
                                    <li><Link to="https://www.facebook.com/hashfairgames" title='Facebook' target="_blank" className="mx-2"><FaFacebook /></Link></li>
                                    <li><Link to="https://twitter.com/Hashfair_Games" title='Twitter' target="_blank" className="mx-2"><FaTwitter /></Link></li>
                                    <li><Link to="https://www.youtube.com/channel/UCt_4P2eqk0Jsn5MNsWapqcA" title='Youtube' target="_blank" className="mx-2"><FaYoutube /></Link></li>
                                    <li><Link to="https://t.me/Hash_Fair_Games" title='Telegram ' target="_blank" className="mx-2"><FaTelegram /></Link></li>
                                    <li className='discord'><Link to="https://discord.gg/yb2UTfUdat" title='Discord' target="_blank" className="mx-2"><FaDiscord /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer