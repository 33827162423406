import { erc20ABI, useAccount, useNetwork } from "wagmi"
import Abi from "../assets/Abi/Nft.json"
import { useContractInstance } from "./useContract"
import { CoinAddress, ContractAddress, defaultNetwork } from "../config"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { createPublicClient, http, formatEther } from "viem"
import newAbi from "../assets/Abi/erc20.json"

const polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mainnet.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mainnet.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mainnet.infura.io/v3"],
            webSocket: ["wss://polygon-mainnet.infura.io/ws/v3"],
        },
        default: {
            http: ["https://burned-frequent-theorem.matic.quiknode.pro/1a12511ca47f77e315007642fd9cf941eda74306/"],
        },
        public: {
            http: ["https://burned-frequent-theorem.matic.quiknode.pro/1a12511ca47f77e315007642fd9cf941eda74306/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
};


export const Approve = () => {
    const { address } = useAccount()
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork

    const Token = useContractInstance(CoinAddress, newAbi, address !== undefined && address !== null)

    const execute = useCallback(async () => {
        try {
            if (isCorrectNetwork) {
                const amt = "11579208923731619542357098500868790785326998466564056403945758400791312963993"
                const txn = await Token.increaseAllowance(ContractAddress, amt);
                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Approving...",
                    success: "Approved Successfully",
                    error: "Failed to Approve",
                });
                toast.dismiss(loadingToast);
            } else {
                if (address === undefined) {
                    toast.error("Connect Your Wallet")
                } else {
                    toast.error("Wrong Network")
                }
            }

        } catch (err) {
            if (address === undefined) {
                toast.error("Connect Your Wallet")
            } else {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            }
        }
    }, [Token, address,isCorrectNetwork])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


}


export const BuyNft = () => {
    const { address } = useAccount()
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork
    const ContractInstance = useContractInstance(ContractAddress, Abi, address !== undefined && address !== null)

    const execute = useCallback(async (price) => {

        try {
            if (isCorrectNetwork) {
                const txn = await ContractInstance.buyNFT(price);
                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Buying NFT...",
                    success: "NFT Bought",
                    error: "Failed to Buy",
                });
                toast.dismiss(loadingToast);
            } else {
                if (address === undefined) {
                    toast.error("Connect Your Wallet")
                } else {
                    toast.error("Wrong Network")
                }
            }

        } catch (err) {
            if (address === undefined) {
                toast.error("Connect Your Wallet")
            } else {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            }

        }

    }, [ContractInstance, address,isCorrectNetwork])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


}


export function Alldata() {
    const { address } = useAccount()
    const [allowance, setAllowance] = useState(0);
    const [hfgBalance, sethfgBalance] = useState(0);
    const [hfgPrice, sethfgPrice] = useState(0);


    const client = createPublicClient({
        chain: polygon,
        transport: http(),
    });

    const fetchHFGTokenInfo = useCallback(async () => {

        try {

            const data = await client.multicall({
                contracts: [
                    {
                        address: CoinAddress,
                        abi: erc20ABI,
                        functionName: 'allowance',
                        args: [address, ContractAddress],
                    },
                    {
                        address: CoinAddress,
                        abi: erc20ABI,
                        functionName: 'balanceOf',
                        args: [address],
                    },


                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setAllowance(formatEther(data[0]))
                sethfgBalance(formatEther(data[1]))
            }

        } catch (error) {
            console.error(error);
        }

    }, [address, client])

    const getPrice = useCallback(async () => {

        try {

            const data = await client.multicall({
                contracts: [

                    {
                        address: ContractAddress,
                        abi: Abi,
                        functionName: 'getPrice',
                        args: [],
                    },


                ],
                allowFailure: false
            })
            if (data.length > 0) {
                sethfgPrice(formatEther(data[0]))
            }


        } catch (error) {
            console.error(error);
        }

    }, [client])




    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                fetchHFGTokenInfo()
            }
            getPrice()

        }, 10000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo, getPrice, address]);

    useEffect(() => {
        if (address) {
            fetchHFGTokenInfo()
        }
        getPrice()

    }, [fetchHFGTokenInfo, getPrice, address])

    return useMemo(() => {
        return {
            allowance,
            hfgBalance,
            hfgPrice
        }
    }, [allowance, hfgBalance, hfgPrice])

}



