import { formatEther } from "ethers/lib/utils";
import { toast } from "react-hot-toast";
import { nftData } from ".";


export const localeFixed = (value, fixed = 2) => {
    return parseFloat(parseFloat(value).toFixed(fixed)).toLocaleString()
}


function shortAddress(address) {
    return `${address.substr(0, 4)}...${address.substr(-4)}`;
}

function formatNumber(val, decimals = 4) {
    return parseFloat(parseFloat(formatEther(val)).toFixed(decimals)).toLocaleString()
}

function toLocale(val, decimals = 4) {
    return Number(parseFloat(val).toFixed(decimals)).toLocaleString()
}


function dateCon(params) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate

}

async function importNFT(contractAddress, tokenId) {

    if (window.ethereum) {
        try {

            const result = await window.ethereum.request({
                "method": 'wallet_watchAsset',
                "params": {
                    "type": "ERC721",
                    "options": {
                        "address": contractAddress,
                        "tokenId": tokenId,
                    },
                },
            });

        } catch (error) {
            toast.error(error.reason ? error.reason : error.data ? error.data.message : error.message)

            console.error('Error importing NFT:', error);
        }
    } else {
        toast.error("No Wallet Detected")

    }

}


function getName(nftAddress) {
    let filteredResult = nftData.find(item => item.address.toLowerCase() === nftAddress.toLowerCase())?.no;
    return filteredResult;
}


export {
    shortAddress,
    formatNumber,
    toLocale,
    dateCon,
    importNFT,
    getName
}
