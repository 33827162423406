import React from 'react'

function Loader() {
  return (
    <div className='w-screen h-screen bg-black flex flex-col justify-center'>
      <img src="images/faviconlogo.gif" className='flex h-52 w-64 justify-center mx-auto' alt="" />
    </div>
  )
}

export default Loader