import { InjectedConnector } from 'wagmi/connectors/injected'
import { providers } from "ethers"
import Web3 from "web3"


export const ContractAddress = "0x39b6a08a73a1Ee1a39B4A98A4cDF6697bAa5FbC5"; // Mainnet address

export const CoinAddress = "0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf";  // Mainnet HFG Token

export const polygonchainRPC = "https://polygon-rpc.com" // Mainnet

export const defaultNetwork = 137 // Mainnet

export const graphQlUrl = "https://api.thegraph.com/subgraphs/name/whyusethis/hf-nft-marketplace"

export const transactionUrl = "https://polygonscan.com/tx/" // Mainnet

export const connector = new InjectedConnector()

export const chainRPC = "https://burned-frequent-theorem.matic.quiknode.pro/1a12511ca47f77e315007642fd9cf941eda74306/"  // Mainnet

export const defaultWeb3 = new Web3(chainRPC)

export const web3Provider = new providers.JsonRpcProvider(chainRPC);


// Mainnet NFT address 
export const nftData = [
  { no: "Blinkers", img: "NFT's/Lvl1.gif", power: 100, value: 100, address: '0xc4D241e10C40D7EE50F00B42cdcc27982E607886' }, 
  { no: "Slotterspot", img: "NFT's/Lvl2.gif", power: 550, value: 500, address: '0x1B923961b3Ab511391326aF3367a85F2FC44c08B' }, 
  { no: "Spinwin", img: "NFT's/Lvl3.gif", power: 1200, value: 1000, address: '0xeb00fabe43f89609eD728011718bDBAa2ae3F743' },  
  { no: "Wildcard", img: "NFT's/Lvl4.gif", power: 2600, value: 2000, address: '0xC35Eb08b3Eb9a8cDd4d0dF30ea1B23bE488F27Df' }, 
  { no: "Thunderbird", img: "NFT's/Lvl5.gif", power: 7500, value: 5000, address: '0xe8068734bA9026ef7893119F4a722c8F83bB0894' }, 
  { no: "Jackpot", img: "NFT's/Lvl6.gif", power: 17000, value: 10000, address: "0x8617b6b37604d8268b7dF0ADe66682B9AB013994" },
  { no: "Kingpin", img: "NFT's/Lvl7.gif", power: 100000, value: 50000, address: "0xE41a3E3d76A6B67bB351Cf913BC3573392dc951F" },
];



// export const ContractAddress = "0x60f78dc7Ed699c017169f08A67Bf904E9D2C9386"; //Testnet address
// export const CoinAddress = "0x351E8507198C56f562670D8dbAeFC0581dD2c3A8";  // HFG Token

