import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";

export const useGraphData = () => {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(0)
    const { address } = useAccount()
    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {

                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{
                        nftboughts(where:{buyer:"${address}"}) {
                          id
                      buyer
                      price
                      timestamp
                      hfgpaid
                      hfgPrice
                      nftAddress
                      tokenId
                      blockNumber
                      blockTimestamp
                      transactionHash
                        }
                      }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data.nftboughts;
                if (res.length > 0) {
                    setLoad(1)
                } else {
                    setLoad(2)
                }
                setData(res)
            }
            else {
                setData([])
                setLoad(2)
            }

        } catch (err) {
            console.error(err)
        }
    }, [address, setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 10000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data,
            load
        }
    }, [data, load])
}


